var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('h1',[_vm._v("Get in Touch")]),_c('p',[_vm._v("Drop me an email by filling in the form below.")]),(_vm.messageSent)?_c('div',{staticClass:"notification notification--success"},[_c('span',{staticClass:"emphasis"},[_vm._v("Success!")]),_c('span',[_vm._v("Thank you, your message was sent.")])]):_vm._e(),(_vm.messageSendFailed)?_c('div',{staticClass:"notification notification--failure"},[_c('span',{staticClass:"emphasis"},[_vm._v("Sorry!")]),_c('span',[_vm._v("There was a problem sending the message, please try again later.")])]):_vm._e(),_c('div',{staticClass:"content__form"},[_c('div',{staticClass:"field",class:{
        'field--populated': _vm.form.sender.name,
        'field--errors': _vm.errors.sender.name
      }},[_c('div',{staticClass:"field__input"},[_c('label',{attrs:{"for":"name"}},[_vm._v("Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.sender.name),expression:"form.sender.name"}],attrs:{"id":"name","type":"text"},domProps:{"value":(_vm.form.sender.name)},on:{"blur":_vm.validateInputs,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form.sender, "name", $event.target.value)}}})]),_c('div',{staticClass:"field__error"},[_c('span',[_vm._v(_vm._s(_vm.errors.sender.name))])])]),_c('div',{staticClass:"field",class:{
        'field--populated': _vm.form.sender.email,
        'field--errors': _vm.errors.sender.email
      }},[_c('div',{staticClass:"field__input"},[_c('label',{attrs:{"for":"email"}},[_vm._v("Email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.sender.email),expression:"form.sender.email"}],attrs:{"id":"email","type":"email"},domProps:{"value":(_vm.form.sender.email)},on:{"blur":_vm.validateInputs,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form.sender, "email", $event.target.value)}}})]),_c('div',{staticClass:"field__error"},[_c('span',[_vm._v(_vm._s(_vm.errors.sender.email))])])]),_c('div',{staticClass:"field field--wide",class:{
        'field--populated': _vm.form.message,
        'field--errors': _vm.errors.message
      }},[_c('div',{staticClass:"field__input"},[_c('label',{attrs:{"for":"message"}},[_vm._v("Message "),(_vm.errors.message)?_c('span',{staticClass:"label--error"},[_vm._v(_vm._s(_vm.errors.message))]):_vm._e()]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.message),expression:"form.message"}],attrs:{"id":"message"},domProps:{"value":(_vm.form.message)},on:{"blur":_vm.validateInputs,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "message", $event.target.value)}}})]),_c('div',{staticClass:"field__error"})]),_c('div',{staticClass:"field field--success"},[_c('button',{staticClass:"field__submit",class:{ 'field__submit--sending': _vm.messageSending },attrs:{"disabled":_vm.messageSending,"type":"submit"},on:{"click":_vm.submit}},[_vm._v(" Submit ")]),_c('div',{class:{ icon__sending: _vm.messageSending }})])])])}
var staticRenderFns = []

export { render, staticRenderFns }