




































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { mapGetters } from "vuex";

interface Form {
  sender: {
    name: string;
    email: string;
  };
  message: string;
}

@Component({
  computed: {
    ...mapGetters(["messageSendFailed"]),
    ...mapGetters(["messageSending"]),
    ...mapGetters(["messageSent"])
  }
})
export default class Contact extends Vue {
  public errors: Form = {
    sender: {
      name: "",
      email: ""
    },
    message: ""
  };

  public form: Form = {
    sender: {
      name: "",
      email: ""
    },
    message: ""
  };

  @Watch("messageSent")
  public watchMessageSuccess(newValue: boolean) {
    if (!newValue) {
      return;
    }

    this.form = {
      sender: {
        name: "",
        email: ""
      },
      message: ""
    };
  }

  public submit() {
    if (!this.validateInputs()) {
      return;
    }

    this.$store.dispatch("sendMessage", this.form);
  }

  public validateInputs(): boolean {
    let hasErrors = false;

    if (this.form.sender.name.length < 2) {
      hasErrors = true;
      this.errors.sender.name = "Please fill in your name";
    } else {
      this.errors.sender.name = "";
    }

    if (!this.validateEmail(this.form.sender.email)) {
      hasErrors = true;
      this.errors.sender.email = "Please provide a valid email address";
    } else {
      this.errors.sender.email = "";
    }

    if (this.form.message.length < 2) {
      hasErrors = true;
      this.errors.message = "Please provide a message";
    } else {
      this.errors.message = "";
    }

    return !hasErrors;
  }

  public validateEmail(email: string) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
}
